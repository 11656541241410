import { Component, OnInit } from "@angular/core";
import { Vehicle } from "../_models";
import { Subscription } from "rxjs";
import { VehiclesService } from "../_services/vehicles.service";
import { Router } from "@angular/router";
import { GlobalVariables } from "../globalVariables";
import { FormBuilder } from "@angular/forms";
import { GlobalToastService } from "../_services/global-toast.service";
import { fadeInAnimation } from "../_animations";
import { Utils } from '../_utils/utils';


@Component({
  selector: "app-vehicles",
  templateUrl: "./vehicles.component.html",
  styleUrls: ["./vehicles.component.css"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class VehiclesComponent implements OnInit {
  vehicles = new Array<Vehicle>();

  searchTextSub: Subscription;

  constructor(
    private vehiclesService: VehiclesService,
    private router: Router,
    private globalVariables: GlobalVariables,
    private fb: FormBuilder,
    private toastService: GlobalToastService
  ) {}

  ngOnInit() {
    this.searchTextSub = this.globalVariables.searchTextSubject.subscribe(
      searchText =>
        this.getAllVehicles({ registration: searchText, name: searchText})
    );
  }

  ngOnDestroy() {
    this.globalVariables.setSearchText("", true);
    this.searchTextSub.unsubscribe();
  }

  getAllVehicles(params = {}, offset = 1, limit = 20) {
    this.vehiclesService.get(params, offset, limit).subscribe(response => {
      this.vehicles = response;
    });
  }

  formatDate(date: Date) {
    return Utils.formatDate(date);
  }
}
