import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Invoice, Vehicle, Product, Customer } from "../_models";
import { map } from "rxjs/operators";

@Injectable()
export class InvoicesService {
  constructor(private http: HttpClient) { }

  public get(params = {}, offset = 1, limit = 20): Observable<Invoice[]> {
    return this.http
      .post<Invoice[]>("api/invoices/get", {
        payload: { params, offset, limit }
      })
      .pipe(
        map((response: any) =>
          response.payload.map(
            (invoice: any) =>
              new Invoice(
                invoice.id,
                invoice.number_invoice,
                invoice.number_quote,
                invoice.payment_method,
                invoice.id_customer,
                invoice.id_vehicle,
                new Vehicle(
                  invoice.id_vehicle,
                  invoice.vin,
                  invoice.registration,
                  invoice.name,
                  new Date(invoice.registration_date),
                  invoice.motorisation,
                  invoice.kilometrage
                ),
                new Customer(
                  invoice.id_customer,
                  invoice.customer_name,
                  invoice.street,
                  invoice.city,
                  invoice.zip_code,
                  invoice.phone,
                  invoice.email
                ),
                invoice.quote,
                new Date(invoice.date),
                invoice.used_asset,
                invoice.asset,
                invoice.id_asset_invoice,
                new Date(invoice.validity),
                invoice.comment,
                invoice.products.map((product: any) =>
                  Product.fromObject(product)
                )
              )
          )
        )
      );
  }

  public getCount(params = {}): Observable<number> {
    return this.http
      .post<Invoice[]>("api/invoices/getCount", {
        payload: { params }
      })
      .pipe(
        map((response: any) => {
          return response.payload[0].count
        }
        )
      );
  }

  public add(invoice): Observable<any> {
    return this.http.put<any>("api/invoices/add", { payload: { invoice } });
  }

  public update(invoice): Observable<any> {
    return this.http.patch<any>("api/invoices/update", {
      payload: { invoice }
    });
  }

  public extract(invoice): Observable<Blob> {
    return this.http.post<any>(
      "api/invoices/extract",
      { payload: { invoice } },
      { responseType: "blob" as "json" }
    );
  }

  public send(invoice): Observable<any> {
    return this.http.post<any>("api/invoices/send", { payload: { invoice } });
  }

  public delete(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: { id } }
    };
    return this.http.delete<any>("api/invoices/delete", options);
  }
}
