export class Product {
  constructor(
    public id: number = 0,
    public id_invoice: number = 0,
    public type: string = "P",
    public reference: string = "",
    public id_rate_mo: number = 1,
    public id_tax: number = 1,
    public designation: string = "",
    public quantity: number = 1,
    public discount: number = 0,
    public tax_type: string = "TX20",
    public tax_percentage: number = 20,
    public rate_mo_name: string = "",
    public rate_mo_price: number = 0,
    public price: number = 0,
    public totalHT: number = 0,
    public totalTTC: number = 0
  ) {}

  getPrice() {
    let price : number = this.price;
    price *= (1 - this.discount / 100) * this.quantity;
    return price;
  }

  getPriceTTC() {
    return (this.getPrice() * (1 + this.tax_percentage / 100)).toFixed(2);
  }

  static fromObject(product: any) {
    return new Product(
      product.id,
      product.id_invoice,
      product.type,
      product.reference,
      product.id_rate_mo,
      product.id_tax,
      product.designation,
      parseFloat(product.quantity),
      product.discount,
      product.tax_type,
      parseFloat(product.tax_percentage),
      product.rate_mo_name,
      parseFloat(product.rate_mo_price),
      parseFloat(product.price)
    );
  }
}
