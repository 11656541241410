import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { GlobalVariables } from "../globalVariables";
import { fadeInAnimation } from "../_animations";
import { Customer } from "../_models";
import { CustomersService } from "../_services/customers.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GlobalToastService } from "../_services/global-toast.service";

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.css"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class CustomersComponent implements OnInit {
  customers = new Array<Customer>();

  searchTextSub: Subscription;

  pages: number[];
  currentPage: number


  constructor(
    private customerService: CustomersService,
    private router: Router,
    private globalVariables: GlobalVariables,
    private fb: FormBuilder,
    private toastService: GlobalToastService
  ) { }

  ngOnInit() {
    this.currentPage = 1;
    console.log(this.globalVariables);

    this.searchTextSub = this.globalVariables.searchTextSubject.subscribe(
      searchText => {
        this.currentPage = 1;
        this.getAllCustomers({ name: searchText, phone: searchText })
      }
    );
  }

  ngOnDestroy() {
    this.globalVariables.setSearchText("", true);
    console.log(this.globalVariables);

    this.searchTextSub.unsubscribe();
  }

  getAllCustomers(params = {}, offset = 1, limit = 20) {
    this.customerService.getCount(params).subscribe(count => {
      const pagesCount = Math.ceil(count / 20);
      this.pages = Array(pagesCount).fill(0).map((x, i) => i + 1);
    });
    this.customerService.get(params, offset, limit).subscribe(response => {
      this.customers = response;
    });
  }

  refreshCustomers(page) {
    this.currentPage = page;
    this.getAllCustomers({}, page);
  }

  addCustomer(customer) {
    this.customerService.add(customer).subscribe(id => {
      this.getAllCustomers();
    });
  }

  submitForm(customer) {
    this.customerService.add(customer).subscribe(
      response => {
        this.toastService.show("Ajout", "Client ajouté !");
        customer = new Customer(
          response.id,
          customer.name,
          customer.street,
          customer.city,
          customer.zip_code,
          customer.phone,
          customer.email
        );
        this.customers.push(customer);
      },
      () => this.toastService.show("Ajout", "Erreur !")
    );
  }
}
