import { Component, OnInit } from "@angular/core";
import { Vehicle, Invoice } from "../_models";
import { VehiclesService } from "../_services/vehicles.service";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoicesService } from "../_services/invoices.service";
import { GlobalToastService } from "../_services/global-toast.service";

@Component({
  selector: "app-vehicle-info",
  templateUrl: "./vehicle-info.component.html",
  styleUrls: ["./vehicle-info.component.css"]
})
export class VehicleInfoComponent implements OnInit {
  vehicle: Vehicle = new Vehicle();
  invoices: Invoice[] = new Array<Invoice>();
  quotes: Invoice[] = new Array<Invoice>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vehicleService: VehiclesService,
    private invoicesService: InvoicesService,
    private toastService: GlobalToastService
  ) {
    let id_vehicle = this.route.snapshot.paramMap.get("id");
    vehicleService.get({ id: id_vehicle }).subscribe(response => {
      this.vehicle = response[0];
    });
    invoicesService.get({ id_vehicle }).subscribe(invoices => {
      invoices.forEach(invoice => {
        invoice.quote ? this.quotes.push(invoice) : this.invoices.push(invoice);
      });
    });
  }

  ngOnInit() {}

  submitForm(vehicle) {
    this.vehicle = Vehicle.fromObject(vehicle);
    this.vehicleService.update(vehicle).subscribe(
      response => {
        this.toastService.show("Modification", "Données véhicule modifiées !");
        this.invoicesService
          .get({ id_vehicle: vehicle.id })
          .subscribe(invoices => {
            this.quotes = [];
            this.invoices = [];
            invoices.forEach(invoice =>
              invoice.quote
                ? this.quotes.push(invoice)
                : this.invoices.push(invoice)
            );
          });
      },
      () => this.toastService.show("Modification", "Erreur !")
    );
  }
}
