import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { FormGroup } from '@angular/forms';

@Directive({
  selector: "[LicensePlate]"
})
export class LicensePlateDirective {

  @Input("LicensePlate") formGroup: FormGroup;

  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "-",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Del",
    "Delete",
    "CapsLock",
    "Control",
    "Shift",
    "Alt",
  ];

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    let field = this.el.nativeElement.value;
    if (event.key.length != 1) return;
    let key = event.key.toUpperCase();
    if (key.match(/\d|[A-Z]/g) == null) return event.preventDefault();
    let lastChar = field[field.length - 1];
    if (
      field != "" &&
      ((lastChar.match(/\d/g) != null && key.match(/[A-Z]/g) != null) ||
        (lastChar.match(/[A-Z]/g) != null && key.match(/\d/g) != null))
    ) {
      this.el.nativeElement.value += "-";
    }
    this.el.nativeElement.value += key;
    this.formGroup.controls['registration'].updateValueAndValidity();
    event.preventDefault();
  }
}
