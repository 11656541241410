import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Product } from "../_models";

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) {}

  public get(params = {}, offset = 1, limit = 100): Observable<Product[]> {
    return this.http
      .post<Product[]>("api/products/get", { payload: {params, offset, limit} })
      .pipe(
        map((response: any) =>
          response.payload.map(product => Product.fromObject(product))
        )
      );
  }

  public add(id, id_invoice): Observable<any> {
    return this.http.put<any>("api/products/add", { payload: {id, id_invoice} });
  }

  public update(product, new_id=product.id): Observable<any> {
    return this.http.patch<any>("api/products/update", { payload: {product, new_id} });
  }

  public swap(id1, id2, id_invoice): Observable<any> {
    return this.http.patch<any>("api/products/swap", { payload: {id1, id2, id_invoice} });
  }


  public delete(id, id_invoice): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: {id, id_invoice} }
    };
    return this.http.delete<any>("api/products/delete", options);
  }
}
