import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Schedule } from "../_models/schedules";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class SchedulesService {
  constructor(private http: HttpClient) {}

  public get(params = {}, offset = 1, limit = 20): Observable<Schedule[]> {
    return this.http
      .post<Schedule[]>("api/schedules/get", { payload: {params, offset, limit} })
      .pipe(
        map((response: any) =>
          response.payload.map(schedule => Schedule.fromObject(schedule))
        )
      );
  }

  public getIn(): Observable<Schedule[]> {
    return this.http
      .get<Schedule[]>("api/schedules/getIn")
      .pipe(
        map((response: any) =>
          response.payload.map(schedule => Schedule.fromObject(schedule))
        )
      );
  }

  public add(schedule): Observable<any> {
    return this.http.put<any>("api/schedules/add", { payload: {schedule} });
  }

  public update(schedule): Observable<any> {
    return this.http.patch<any>("api/schedules/update", { payload: {schedule} });
  }

  public delete(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: {id} }
    };
    return this.http.delete<any>("api/schedules/delete", options);
  }
}
