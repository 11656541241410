import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Customer } from "../_models";

@Component({
  selector: "app-customer-form",
  templateUrl: "./customer-form.component.html",
  styleUrls: ["./customer-form.component.css"]
})
export class CustomerFormComponent implements OnInit {
  customerForm: FormGroup;

  @Output() customerFormEmitter = new EventEmitter<Customer>();
  @Input() buttonText: string = "Default";
  @Input() customerDefault: Customer = new Customer(0, "", "", "", "", "", "");
  @Input() isReadonly: boolean = false;
  @Input() showButton: boolean = true;

  constructor(private fb: FormBuilder) {
    this.customerForm = this.fb.group({
      id: [""],
      name: [this.customerDefault.name, [Validators.required]],
      street: [this.customerDefault.street, [Validators.required]],
      zip_code: [
        this.customerDefault.zip_code,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern("^[0-9]*$")
        ]
      ],
      city: [this.customerDefault.city, [Validators.required]],
      phone: [this.customerDefault.phone, [Validators.required]],
      email: [this.customerDefault.email, [Validators.required]]
    });
  }

  ngOnChanges() {
    if (this.customerDefault) this.customerForm.setValue(this.customerDefault);
  }

  ngOnInit() {}

  submitForm() {
    this.customerFormEmitter.emit(this.customerForm.value);
  }
}
