export class RateMo {
    constructor(
      public id: number = 0,
      public name: string = "",
      public price: number = 0) {
    }
  
    static fromObject(rate_mo: any) {
      return new RateMo(rate_mo.id, rate_mo.name, rate_mo.price);
    }
  }
  