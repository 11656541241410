import { Component, OnInit } from "@angular/core";
import { GlobalVariables } from "../globalVariables";
import { ConfigurationsService } from "../_services/configurations.service";
import { AuthenticationService } from "../_services/authentication.service";
import { trigger, transition, style, animate, state } from "@angular/animations";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  animations: [
    trigger("slideInOut", [
      state("open", style({
        transform: 'translateY(0%)'
      })),
      state("closed", style({
        transform: 'translateY(-100%)'
      })),
      transition("closed => open", [
        /*style({ transform: "translateY(-100%)" }),*/
        animate("200ms ease-in", style({ transform: "translateY(0%)" })),
      ]),
      transition("open => closed", [
        animate("200ms ease-in", style({ transform: "translateY(-100%)" })),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  isNavbarCollapsed = true;
  searchText: string;
  title: string;
  loggedIn: boolean;
  constructor(
    private globalVariable: GlobalVariables,
    private configurationsService: ConfigurationsService,
    private authenticationService: AuthenticationService
  ) {
    this.title = "";
    if (this.loggedIn) {
      configurationsService
        .get({ name: "garage_name" })
        .subscribe((results) => {
          this.title = results[0].value;
        });
    }
  }

  ngOnInit() {
    this.loggedIn = false;
    this.authenticationService.currentUser.subscribe((user) => {
      if (user == null) {
        this.loggedIn = false;
        return;
      } 
      this.loggedIn = true;
      this.configurationsService
        .get({ name: "garage_name" })
        .subscribe((results) => {
          this.title = results[0].value;
        }, (err) => {
          this.title = "";
        });
    });
  }

  onSearchChange(searchText: string) {
    this.globalVariable.setSearchText(searchText);
  }

  logout() {
    this.authenticationService.logout();
  }
}
