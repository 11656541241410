import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Product } from "../_models";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { ProductsService } from "../_services/products.service";
import { GlobalToastService } from "../_services/global-toast.service";
import { RatesMoService } from "../_services/rates-mo.service";
import { from, forkJoin, Observable, of } from "rxjs";
import { Utils } from "../_utils/utils";
import {
  concatMap,
  toArray,
  pairwise,
  startWith,
  mergeMap,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  map,
} from "rxjs/operators";
import { KeyValue } from "@angular/common";

@Component({
  selector: "app-products-form",
  templateUrl: "./products-form.component.html",
  styleUrls: ["./products-form.component.css"],
})
export class ProductsFormComponent implements OnInit {
  productsForm: FormGroup;
  productsFormArray: FormArray;
  rates_mo: any;

  @Output() productsFormEmitter = new EventEmitter<Product>();
  @Input() idInvoice: number = 0;
  @Input() disableForm: boolean = false;
  @Input() productsDefault: Array<Product> = new Array<Product>();

  searching: boolean = false;
  searchFailed: boolean = false;
  search = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.productsService.get({ designation: term }, 1, 100).pipe(
          map((products) =>
            products
              .filter((product) => product.designation != "")
              .map((product) => product.designation)
              .filter(Utils.onlyUnique)
          ),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  constructor(
    private fb: FormBuilder,
    private rates_moService: RatesMoService,
    private productsService: ProductsService,
    private toastService: GlobalToastService
  ) {
    this.rates_mo = {};
    this.rates_moService.get().subscribe((rates_mo) => {
      rates_mo.forEach((rate_mo) => {
        this.rates_mo[rate_mo.id + ""] = {
          name: rate_mo.name,
          price: rate_mo.price,
        };
      });
      rates_mo = rates_mo.sort((a, b) => (a.name < b.name ? -1 : 1));
    });
    this.productsForm = fb.group({
      products: fb.array([]),
    });
    this.productsFormArray = this.productsForm.get("products") as FormArray;
  }

  orderRatesMo(a: KeyValue<number, any>, b: KeyValue<number, any>) {
    return a.value.name < b.value.name ? -1 : 1;
  }

  ngOnChanges() {
    if (this.productsDefault && this.productsDefault.length != 0) {
      this.productsFormArray.clear();
      this.productsDefault.forEach((product) => {
        this.productsFormArray.push(this.createProduct(product));
      });
      if (this.disableForm) this.productsForm.disable();
    }
  }

  createProduct(product: Product) {
    let group = this.fb.group({ ...product, priceTTC: product.getPriceTTC() });
    if (this.disableForm) return group;
    group.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (next.type == "MO") {
          next.rate_mo_price = this.rates_mo[next.id_rate_mo].price;
          next.price = this.rates_mo[next.id_rate_mo].price;
        }

        this.productsService.update(next).subscribe();
        this.productsDefault[next.id] = Product.fromObject(next);
        group.patchValue(
          { ...next, priceTTC: this.productsDefault[next.id].getPriceTTC() },
          { emitEvent: false }
        );
      });
    return group;
  }

  addProduct() {
    this.productsService
      .add(this.productsFormArray.length, this.idInvoice)
      .subscribe(
        (result) => {
          let product = new Product();
          product.id = this.productsFormArray.length;
          product.id_invoice = this.idInvoice;

          this.productsDefault.push(product);
          this.productsFormArray.push(this.createProduct(product));
        },
        () => this.toastService.show("Ajout produit", "Erreur serveur !")
      );
  }

  deleteProduct(index: number) {
    let id = this.productsFormArray.value[index].id;
    this.productsService.delete(id, this.idInvoice).subscribe(
      (result) => {
        this.productsDefault.splice(index, 1);
        this.productsFormArray.removeAt(index);
        from(this.productsDefault.slice(index))
          .pipe(
            concatMap((product) =>
              this.productsService.update(product, product.id - 1)
            ),
            toArray()
          )
          .subscribe((results) => {
            this.productsDefault.forEach((product, i, products) => {
              this.productsDefault[i].id = i;
              this.productsFormArray.value[i].id = i;
            });
          });
      },
      () => this.toastService.show("Suppression produit", "Erreur serveur !")
    );
  }

  ngOnInit() {}

  onSubmit() {}

  swap(index1: number, index2: number) {
    let extras = [...this.productsFormArray.value];
    if (index2 > 0 && index1 < extras.length - 1) {
      this.productsService
        .swap(index1, index2, this.idInvoice)
        .subscribe((results) => {
          extras[index1].id = index2;
          extras[index2].id = index1;
          [extras[index1], extras[index2]] = [extras[index2], extras[index1]];

          this.productsDefault[index1].id = index2;
          this.productsDefault[index2].id = index1;
          [this.productsDefault[index1], this.productsDefault[index2]] = [
            this.productsDefault[index2],
            this.productsDefault[index1],
          ];
          this.productsFormArray.setValue(extras, { emitEvent: false });
        });
    }
  }
}
