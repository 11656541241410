import { Vehicle } from "./vehicle";
import { Product } from "./product";
import { Customer } from "./customers";

export class Invoice {
  constructor(
    public id: number = 0,
    public number_invoice: number = 0,
    public number_quote: number = 0,
    public payment_method: string = "",
    public id_customer: number = 0,
    public id_vehicle: number = 0,
    public vehicle: Vehicle = new Vehicle(),
    public customer: Customer = new Customer(),
    public quote: boolean = false,
    public date: Date = new Date(),
    public used_asset: number = 0,
    public asset: number = 0,
    public id_asset_invoice: number = 0,
    public validity: Date = new Date(),
    public comment: string = "",
    public products: Product[] = new Array<Product>()
  ) {}

  static fromObject(invoice: any) {
    return;
  }

  getName() {
    return this.quote
      ? "Devis_n°" + this.id
      : "Facture_n°" + this.number_invoice;
  }

  getTotalPrice(tax: boolean = true, asset: boolean = false) {
    let total = 0;
    this.products.forEach((element) => {
      total +=
        element.getPrice() * (tax ? 1 + element.tax_percentage / 100 : 1);
    });
    return (total - (asset ? this.asset : 0)).toFixed(2);
  }

  getLeftAsset() {
    let left = parseFloat(this.getTotalPrice()) - this.used_asset;
    return left;
  }

  getTotalTax() {
    return (
      parseFloat(this.getTotalPrice()) - parseFloat(this.getTotalPrice(false))
    ).toFixed(2);
  }

  getTotalMOPrice(tax: boolean = true) {
    let total = 0;
    this.products.forEach((element) => {
      if (element.type == "MO")
        total +=
          element.getPrice() * (tax ? 1 + element.tax_percentage / 100 : 1);
    });
    return total.toFixed(2);
  }

  getTotalMOTax() {
    return (
      parseFloat(this.getTotalMOPrice()) -
      parseFloat(this.getTotalMOPrice(false))
    ).toFixed(2);
  }

  getTotalMOTime() {
    let total = 0;
    this.products.forEach((element) => {
      if (element.type == "MO") {
        total += element.quantity;
      }
    });
    return total.toFixed(2);
  }

  getTotalPartPrice(tax: boolean = true) {
    let total = 0;
    this.products.forEach((element) => {
      if (element.type == "P")
        total +=
          element.getPrice() * (tax ? 1 + element.tax_percentage / 100 : 1);
    });
    return total.toFixed(2);
  }

  getTotalPartTax() {
    return (
      parseFloat(this.getTotalPartPrice()) -
      parseFloat(this.getTotalPartPrice(false))
    ).toFixed(2);
  }

  toSend(invoice: Invoice) {
    let toSend = {
      ...invoice,
      totalMOHT: invoice.getTotalMOPrice(false),
      totalMOTTC: invoice.getTotalMOPrice(),
      totalMOTax: invoice.getTotalMOTax(),
      totalMOTime: invoice.getTotalMOTime(),
      totalPartHT: invoice.getTotalPartPrice(false),
      totalPartTTC: invoice.getTotalPartPrice(),
      totalPartTax: invoice.getTotalPartTax(),
      totalHT: invoice.getTotalPrice(false),
      totalTTC: invoice.getTotalPrice(true, false),
      totalTTCNet: invoice.getTotalPrice(true, true),
      totalTax: invoice.getTotalTax(),
      totalAsset: invoice.asset.toFixed(2),
      rates_mo: {},
    };

    toSend.products.forEach((product, index, products) => {
      if (product.type === "MO") {
        toSend.products[index].reference = product.rate_mo_name;
        toSend.rates_mo[product.rate_mo_name] = toSend.products[index].price;
      }
      toSend.products[index].totalHT = product.price * product.quantity;
      toSend.products[index].totalTTC = parseFloat(
        toSend.products[index].getPriceTTC()
      );
    });
    return toSend;
  }
}
