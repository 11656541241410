import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { fadeInAnimation } from "../_animations";
import { Customer, Invoice, Product } from "../_models";
import { CustomersService } from "../_services/customers.service";
import { InvoicesService } from "../_services/invoices.service";
import { GlobalToastService } from "../_services/global-toast.service";
import { SchedulesService } from "../_services/schedules.service";
import { Utils } from '../_utils/utils';


@Component({
  selector: 'app-pagination',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class InvoicesComponent implements OnInit {
  invoices: Invoice[] = new Array<Invoice>();
  quotes: Invoice[] = new Array<Invoice>();
  pages: number[];
  currentPage: number;
  showQuotes: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomersService,
    private invoicesService: InvoicesService,
    private schedulesService: SchedulesService,
    private toastService: GlobalToastService
  ) { }

  ngOnInit() {
    this.currentPage = 1;
    this.refreshInvoices(1);
    this.pages = [];
  }

  onShowQuotesChange() {
    this.refreshInvoices(1);
  }

  ngOnDestroy() {
  }

  formatDate(date: Date) {
    return Utils.formatDate(date);
  }

  refreshInvoices(page) {
    this.currentPage = page;
    this.invoicesService.getCount({ quote: this.showQuotes ? 1 : 0 }).subscribe(count => {
      const pagesCount = Math.ceil(count / 20);
      this.pages = Array(pagesCount).fill(0).map((x, i) => i + 1);
    });
    this.invoicesService
      .get({ quote: this.showQuotes ? 1 : 0 }, page)
      .subscribe(invoices => {
        this.invoices = [];
        invoices.forEach(invoice => {
          this.invoices.push(invoice)
        });
      });
  }
  extract(invoice: Invoice) {
    let toSend = invoice.toSend(invoice);
    this.invoicesService.extract(toSend).subscribe(x => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([x], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement("a");
      link.href = data;
      link.download = "facture" + invoice.number_invoice + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
