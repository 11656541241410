export class Customer {
  constructor(
    public id: number = 0,
    public name: string = "",
    public street: string = "",
    public city: string = "",
    public zip_code: string = "",
    public phone: string = "",
    public email: string = "") {
  }

  static fromObject(customer: any) {
    return new Customer(customer.id, customer.name, customer.street, customer.city, customer.zip_code, customer.phone, customer.email);
  }
}
