export class Schedule {
  constructor(
    public id: number = 0,
    public id_worker: number = 0,
    public id_invoice: number = 0,
    public type: string = "",
    public event: string = "",
    public planned: Date = new Date(0)) {
  }

  static fromObject(schedule: any) {
    return new Schedule(schedule.id, schedule.id_worker, schedule.id_invoice, schedule.type, schedule.event, new Date(schedule.planned == null ? 0 : schedule.planned));
  }
}
