import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { CustomersComponent } from "./customers/customers.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomersService } from "./_services/customers.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NavbarComponent } from "./navbar/navbar.component";
import { CustomerInfosComponent } from "./customer-infos/customer-infos.component";
import { GlobalVariables } from "./globalVariables";
import { InvoicesService } from "./_services/invoices.service";
import { CustomerFormComponent } from "./customer-form/customer-form.component";
import { ToastComponent } from "./toast/toast.component";
import { GlobalToastService } from "./_services/global-toast.service";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { VehicleFormComponent } from "./vehicle-form/vehicle-form.component";
import { ProductsFormComponent } from "./products-form/products-form.component";
import { ProductsService } from "./_services/products.service";
import { OnlyNumberDirective } from "./_directives/only-number.directive";
import { InvoiceCreateComponent } from "./invoice-create/invoice-create.component";
import { LicensePlateDirective } from "./_directives/license-plate.directive";
import { VehiclesService } from "./_services/vehicles.service";
import { RecaptchaModule } from "ng-recaptcha";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";
import { VehiclesComponent } from "./vehicles/vehicles.component";
import { RatesMoService } from "./_services/rates-mo.service";
import { VehicleInfoComponent } from "./vehicle-info/vehicle-info.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { WorkersService } from "./_services/workers.service";
import { SchedulesService } from "./_services/schedules.service";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { SchedulingComponent } from "./scheduling/scheduling.component";
import { ConfigurationsService } from './_services/configurations.service';
import { LoginComponent } from './login/login.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { InvoicesComponent } from "./invoices/invoices.component";

registerLocaleData(localeFr, "fr-FR", localeFrExtra);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    InvoiceComponent,
    InvoicesComponent,
    CustomersComponent,
    NavbarComponent,
    CustomerInfosComponent,
    CustomerFormComponent,
    ToastComponent,
    VehicleFormComponent,
    ProductsFormComponent,
    OnlyNumberDirective,
    InvoiceCreateComponent,
    LicensePlateDirective,
    VehiclesComponent,
    VehicleInfoComponent,
    ConfigurationComponent,
    SchedulingComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    PerfectScrollbarModule,
    RecaptchaModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CustomersService,
    ProductsService,
    InvoicesService,
    GlobalVariables,
    GlobalToastService,
    VehiclesService,
    RatesMoService,
    WorkersService,
    SchedulesService,
    ConfigurationsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
