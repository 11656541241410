import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { fadeInAnimation } from "../_animations";
import { Customer, Invoice, Product } from "../_models";
import { CustomersService } from "../_services/customers.service";
import { InvoicesService } from "../_services/invoices.service";
import { GlobalToastService } from "../_services/global-toast.service";
import { SchedulesService } from "../_services/schedules.service";

@Component({
  selector: "app-customer-infos",
  templateUrl: "./customer-infos.component.html",
  styleUrls: ["./customer-infos.component.css"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class CustomerInfosComponent implements OnInit {
  customer: Customer = new Customer();
  invoices: Invoice[] = new Array<Invoice>();
  quotes: Invoice[] = new Array<Invoice>();
  removeConfirm: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomersService,
    private invoicesService: InvoicesService,
    private schedulesService: SchedulesService,
    private toastService: GlobalToastService
  ) {
    let id_customer = this.route.snapshot.paramMap.get("id");
    customerService.get({ id: id_customer }).subscribe(response => {
      this.customer = response[0];
      this.refreshInvoices();
    });
  }

  ngOnInit() {}

  sendInvoice(invoice: Invoice) {
    let toSend = invoice.toSend(invoice);
    this.invoicesService.send(invoice).subscribe();
  }

  extract(invoice: Invoice) {
    let toSend = invoice.toSend(invoice);
    this.invoicesService.extract(toSend).subscribe(x => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([x], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement("a");
      link.href = data;
      link.download = "facture" + invoice.number_invoice + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      setTimeout(function() {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  deleteCustomer() {
    this.customerService.delete(this.customer.id).subscribe(
      response => {
        this.toastService.show("Suppresion", "Données client supprimées !");
        this.router.navigate(["/customers"]);
      },
      () => this.toastService.show("Suppression", "Erreur !")
    );
  }

  deleteInvoice(id_invoice) {
    this.invoicesService.delete(id_invoice).subscribe(
      response => {
        this.toastService.show("Suppresion", "Données devis supprimées !");
        this.refreshInvoices();
      },
      () => this.toastService.show("Suppression", "Erreur !")
    );
  }

  deleteConfirm() {
    this.removeConfirm = !this.removeConfirm;
  }

  refreshInvoices() {
    this.invoicesService
      .get({ id_customer: this.customer.id })
      .subscribe(invoices => {
        this.quotes = [];
        this.invoices = [];
        invoices.forEach(invoice =>
          invoice.quote
            ? this.quotes.push(invoice)
            : this.invoices.push(invoice)
        );
      });
  }

  scheduleQuote(quote: Invoice) {
    this.schedulesService
      .add({
        id_invoice: quote.id,
        type: "IN",
        event: quote.vehicle.name + ", " + quote.vehicle.registration,
        planned: new Date(0)
      })
      .subscribe(response => {
        this.router.navigate(["/scheduling"]);
      });
  }

  submitForm(customer) {
    this.customer = Customer.fromObject(customer);
    this.customerService.update(customer).subscribe(
      response => {
        this.toastService.show("Modification", "Données client modifiées !");
        this.refreshInvoices();
      },
      () => this.toastService.show("Modification", "Erreur !")
    );
  }
}
