import { Component, OnInit } from '@angular/core';
import { GlobalVariables } from '../globalVariables';
import { fadeInAnimation } from '../_animations';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' }
})
export class DashboardComponent implements OnInit {

  constructor(private globalVariables: GlobalVariables) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.globalVariables.setSearchText("");
  }

}
