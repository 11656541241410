import { Component, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { RatesMoService } from "../_services/rates-mo.service";
import { Worker, RateMo } from "../_models";
import { startWith, pairwise, map } from "rxjs/operators";
import { WorkersService } from "../_services/workers.service";
import { ConfigurationsService } from "../_services/configurations.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.css"]
})
export class ConfigurationComponent implements OnInit {
  ratesMoForm: FormGroup;
  ratesMoFormArray: FormArray;

  workersForm: FormGroup;
  workersFormArray: FormArray;

  garageForm: FormGroup;
  fileToUpload: File = null;

  constructor(
    private fb: FormBuilder,
    private rates_moService: RatesMoService,
    private workersService: WorkersService,
    private configurationsService: ConfigurationsService
  ) {
    this.ratesMoForm = this.fb.group({
      ratesMo: this.fb.array([])
    });
    this.ratesMoFormArray = this.ratesMoForm.get("ratesMo") as FormArray;
    this.rates_moService.get().subscribe(rates_mo => {
      rates_mo.forEach(rate_mo => {
        this.ratesMoFormArray.push(this.createRateMo(rate_mo));
      });
      this.sortRatesMoFormArray();
    });

    this.workersForm = this.fb.group({
      workers: this.fb.array([])
    });
    this.workersFormArray = this.workersForm.get("workers") as FormArray;
    this.workersService.get().subscribe(workers => {
      workers.forEach(worker => {
        this.workersFormArray.push(this.createWorker(worker));
      });
    });

    let garageConfigs = [
      "garage_name",
      "garage_address",
      "garage_zip_code",
      "garage_city",
      "garage_phone",
      "garage_mobile_phone",
      "garage_email",
      "garage_type",
      "garage_capital",
      "garage_rcs",
      "garage_siret",
      "garage_naf"
    ];
    let garageFormGroup = {};
    garageConfigs.forEach(conf => {
      garageFormGroup[conf] = [""];
    });
    this.garageForm = this.fb.group(garageFormGroup);

    let garageConfigsObservables = [];
    Object.keys(this.garageForm.controls).forEach(key => {
      garageConfigsObservables.push(
        this.configurationsService.get({ name: key }).pipe(
          map(results => ({
            name: key,
            value: results[0] ? results[0].value : ""
          }))
        )
      );
      this.garageForm.controls[key].valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          this.configurationsService
            .add({ name: key, value: next })
            .subscribe();
          this.garageForm.patchValue({ ...next }, { emitEvent: false });
        });
    });

    forkJoin(garageConfigsObservables).subscribe(results => {
      let configs = {};
      results.forEach(config => {
        configs[config.name] = config.value;
      });
      this.garageForm.patchValue(configs, {emitEvent: false});
    });
  }

  createWorker(worker: Worker) {
    let group = this.fb.group({ ...worker });
    group.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.workersService.update(next).subscribe();
        group.patchValue({ ...next }, { emitEvent: false });
      });
    return group;
  }

  addWorker() {
    let worker = new Worker();
    this.workersService.add(worker).subscribe(response => {
      worker.id = response.id;
      this.workersFormArray.push(this.createWorker(worker));
    });
  }

  removeWorker(index) {
    let workerId = parseInt(this.workersFormArray.at(index).get("id").value);
    this.workersService.delete(workerId).subscribe(response => {
      this.workersFormArray.removeAt(index);
    });
  }

  sortRatesMoFormArray() {
    let array: Array<RateMo> = this.ratesMoFormArray.value;
    array.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.ratesMoFormArray.patchValue(array, { emitEvent: false });
  }

  createRateMo(rate_mo: RateMo) {
    let group = this.fb.group({ ...rate_mo });
    group.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.rates_moService.update(next).subscribe();
        group.patchValue({ ...next }, { emitEvent: false });
      });
    return group;
  }

  addRateMo() {
    let rateMo = new RateMo();
    this.rates_moService.add(rateMo).subscribe(response => {
      rateMo.id = response.id;
      this.ratesMoFormArray.push(this.createRateMo(rateMo));
    });
  }

  removeRateMo(index) {
    let rateMoId = parseInt(this.ratesMoFormArray.at(index).get("id").value);
    this.rates_moService.delete(rateMoId).subscribe(response => {
      this.ratesMoFormArray.removeAt(index);
      this.sortRatesMoFormArray();
    });
  }

  handleFileInput(files: FileList) {
    this.configurationsService.logo(files[0]).subscribe((result) => console.log(result));
}

  ngOnInit() {}
}
