import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Vehicle } from "../_models";
import { Utils } from "../_utils/utils";
import { VehiclesService } from "../_services/vehicles.service";
import { Observable, of } from "rxjs";
import {
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  catchError
} from "rxjs/operators";

@Component({
  selector: "app-vehicle-form",
  templateUrl: "./vehicle-form.component.html",
  styleUrls: ["./vehicle-form.component.css"]
})
export class VehicleFormComponent implements OnInit {
  vehicleForm: FormGroup;
  fetchingRegistration: boolean = false;
  sitekey: string = "";

  @Output() vehicleFormEmitter = new EventEmitter<Vehicle>();
  @Input() buttonText: string = "Default";
  @Input() vehicleDefault: Vehicle = new Vehicle();
  @Input() isReadonly: boolean = false;
  @Input() showButton: boolean = true;

  constructor(
    private fb: FormBuilder,
    private vehiclesService: VehiclesService
  ) {
    this.vehicleForm = fb.group({
      id: [""],
      vin: [this.vehicleDefault.vin],
      registration: [
        this.vehicleDefault.registration,
        [
          Validators.required /*Validators.pattern(/([A-Z]{2}-\d{3}-[A-Z]{2})|(\d{1,4} [A-Z]{1,3} \d{2})/)*/
        ]
      ],
      name: [this.vehicleDefault.name, Validators.required],
      registration_date: [
        Utils.formatDate(this.vehicleDefault.registration_date),
        [Validators.required, Validators.pattern(/\d+\/\d+\/\d+/)]
      ],
      motorisation: [this.vehicleDefault.motorisation, Validators.required],
      kilometrage: [this.vehicleDefault.kilometrage, Validators.required]
    });
  }

  ngOnChanges() {
    if (this.vehicleDefault) {
      this.vehicleForm.setValue({
        ...this.vehicleDefault,
        registration_date: Utils.formatDate(
          this.vehicleDefault.registration_date
        )
      });
    }
  }

  searching: boolean = false;
  searchFailed: boolean = false;
  search = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap(term =>
        this.vehiclesService.get({ registration: term }, 1, 20).pipe(
          map(vehicles => vehicles.map(vehicle => vehicle.registration)),
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  onSearch(event) {
    this.vehiclesService
      .get({ registration: event.item })
      .subscribe(vehicles => {
        console.log({
          ...vehicles[0],
          registration_date: Utils.formatDate(vehicles[0].registration_date)
        });
        this.vehicleForm.setValue({
          ...vehicles[0],
          registration_date: Utils.formatDate(vehicles[0].registration_date)
        });
      });
  }

  ngOnInit() {}

  getDateFromRegistration() {
    this.vehiclesService
      .getDataFromRegistration(this.vehicleForm.controls["registration"].value)
      .subscribe(
        vehicle =>
          this.vehicleForm.patchValue({
            name: vehicle.name,
            motorisation: vehicle.motorisation
          }),
        err => console.log(err)
      );
  }

  submitForm() {
    let date = this.vehicleForm.value.registration_date;
    let val = {
      ...this.vehicleForm.value,
      registration_date:
        date.match(/\d+\/\d+\/(\d+)/)[1] +
        "-" +
        date.match(/\d+\/(\d+)\/\d+/)[1] +
        "-" +
        date.match(/(\d+)\/\d+\/\d+/)[1]
    };
    this.vehicleFormEmitter.emit(val);
  }
}
