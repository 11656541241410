export class Utils {
  static formatDate(date: Date) {
    return (
      date.getUTCDate().toString().padStart(2, "0") +
      "/" +
      (date.getUTCMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getFullYear()
    );
  }
  static onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
