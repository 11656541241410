import { Component, OnInit } from "@angular/core";
import {
  CalendarView,
  CalendarEvent,
  DAYS_OF_WEEK,
  CalendarEventTimesChangedEvent,
  CalendarDateFormatter,
  DateFormatterParams
} from "angular-calendar";
import { Observable, Subject } from "rxjs";
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format
} from "date-fns";
import { HttpParams, HttpClient } from "@angular/common/http";
import { map, filter } from "rxjs/operators";
import { SchedulesService } from "../_services/schedules.service";
import { Schedule } from "../_models";
import { Router } from "@angular/router";
import { InvoicesService } from "../_services/invoices.service";
import { DatePipe } from "@angular/common";
import { GlobalToastService } from "../_services/global-toast.service";
import { fadeInAnimation } from '../_animations';

const colors: any = {
  IN: {
    primary: "#00FF00",
    secondary: "#FAE3E3"
  },
  OUT: {
    primary: "#FF0000",
    secondary: "#FAE3E3"
  },
  RDV: {
    primary: "#0000FF",
    secondary: "#FAE3E3"
  }
};

class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, "HH:mm", locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
}

@Component({
  selector: "app-scheduling",
  templateUrl: "./scheduling.component.html",
  styleUrls: ["./scheduling.component.css"],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
  styles: [
    `
      .drag-active {
        position: relative;
        z-index: 1;
        pointer-events: none;
      }
      .drag-over {
        background-color: #eee;
      }
    `
  ],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class SchedulingComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  locale: string = "fr-FR";

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  events: Array<CalendarEvent<{ schedule: Schedule }>>;
  externalEvents: Array<CalendarEvent<{ schedule: Schedule }>>;
  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  constructor(
    private http: HttpClient,
    private schedulesService: SchedulesService,
    private invoicesService: InvoicesService,
    private globalToastService: GlobalToastService,
    private router: Router
  ) {
    this.events = new Array<CalendarEvent<{ schedule: Schedule }>>();
    this.externalEvents = new Array<CalendarEvent<{ schedule: Schedule }>>();
  }

  ngOnInit(): void {
    this.fetchEvents();
  }

  fetchEvents(): void {
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay
    }[this.view];

    this.schedulesService.get().subscribe(schedules => {
      this.events = [];
      this.externalEvents = [];
      schedules.forEach(schedule => {
        let event = {
          title: schedule.event,
          start: schedule.planned,
          color: colors[schedule.type],
          meta: {
            schedule
          },
          draggable: true,
          actions: [
            {
              label: '<span class="event-delete">X</span>',
              onClick: ({
                event
              }: {
                event: CalendarEvent<{ schedule: Schedule }>;
              }): void => {
                let id = event.meta.schedule.id;
                this.schedulesService.delete(id).subscribe(result => {
                  if (result.success) this.fetchEvents();
                });
              }
            }
          ]
        };
        if (schedule.planned.valueOf() != 0) this.events.push(event);
        else this.externalEvents.push(event);
      });
      this.schedulesService.getIn().subscribe(schedules => {
        schedules.forEach(schedule => {
          schedule.id = 0;
          schedule.type = "OUT";
          this.externalEvents.push({
            title: schedule.event,
            start: schedule.planned,
            color: colors.OUT,
            meta: {
              schedule
            },
            draggable: true
          });
        });
      });
    });
    this.refresh.next();
  }

  deleteEvent(event: CalendarEvent<{ schedule: Schedule }>) {
    let id = event.meta.schedule.id;
    if (id != 0)
      this.schedulesService.delete(id).subscribe(result => {
        if (result.success) this.fetchEvents();
      });
  }

  dayClicked({
    date,
    events
  }: {
    date: Date;
    events: Array<CalendarEvent<{ schedule: Schedule }>>;
  }): void {
    this.viewDate = date;
    this.view = CalendarView.Week;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    let schedule = event.meta.schedule;
    const externalIndex = this.externalEvents.indexOf(event);
    if (externalIndex > -1) {
      if (schedule.id == 0) {
        this.schedulesService
          .add({
            id_invoice: schedule.id_invoice,
            planned: newStart,
            event: schedule.event,
            type: schedule.type
          })
          .subscribe(response => {
            this.externalEvents.splice(externalIndex, 1);
            schedule.id = response.id;
            schedule.planned = newStart;
            this.events.push({
              title: schedule.event,
              start: schedule.planned,
              color: colors[schedule.type],
              meta: {
                schedule
              },
              draggable: true,
              actions: [
                {
                  label: '<span class="event-delete">X</span>',
                  onClick: ({
                    event
                  }: {
                    event: CalendarEvent<{ schedule: Schedule }>;
                  }): void => {
                    let id = event.meta.schedule.id;
                    this.schedulesService.delete(id).subscribe(result => {
                      if (result.success) this.fetchEvents();
                    });
                  }
                }
              ]
            });
            this.refresh.next();
          });
        return;
      }
      newStart.setHours(12);
    }

    event.start = newStart;
    event.end = newEnd;
    let id = schedule.id;
    if (externalIndex != -1) {
      event.actions = [
        {
          label: '<span class="event-delete">X</span>',
          onClick: ({
            event
          }: {
            event: CalendarEvent<{ schedule: Schedule }>;
          }): void => {
            let id = event.meta.schedule.id;
            this.schedulesService.delete(id).subscribe(result => {
              if (result.success) this.fetchEvents();
            });
          }
        }
      ];
      this.externalEvents.splice(externalIndex, 1);
      this.events.push(event);
    }
    this.refresh.next();
    this.schedulesService.update({ id, planned: newStart }).subscribe(
      response => {},
      reponse => {
        this.globalToastService.show("Planning", "Erreur planning");
      }
    );
  }

  addEvent(event) {
    let schedule = new Schedule();
    schedule.event = event.value;
    schedule.id_worker = null;
    schedule.id_invoice = null;
    schedule.type = "RDV";
    this.schedulesService
      .add({
        planned: new Date(0),
        event: event.value,
        type: "RDV"
      })
      .subscribe(response => {
        event.value = "";
        schedule.id = response.id;
        this.externalEvents.push({
          title: schedule.event,
          start: schedule.planned,
          color: colors[schedule.type],
          meta: {
            schedule
          },
          draggable: true
        });
      });
  }

  eventClicked(event: CalendarEvent<{ schedule: Schedule }>): void {
    let id_invoice = event.meta.schedule.id_invoice;
    // if (id_invoice != null)
    // this.router.navigate(["/invoice", event.meta.schedule.id_invoice]);
  }

  externalDrop(event: CalendarEvent) {
    if (this.externalEvents.indexOf(event) === -1) {
      let schedule = event.meta.schedule;
      schedule.planned = new Date(0);
      this.events = this.events.filter(iEvent => iEvent !== event);
      this.schedulesService.update(schedule).subscribe(response => {
        this.externalEvents.push(event);
      });
    }
  }
}
