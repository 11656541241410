import { Component, OnInit } from "@angular/core";
import { fadeInAnimation } from "../_animations";
import { CustomersService } from "../_services/customers.service";
import { Customer, Invoice } from "../_models";
import { ActivatedRoute, Router } from "@angular/router";
import { VehiclesService } from "../_services/vehicles.service";
import { GlobalToastService } from "../_services/global-toast.service";
import { InvoicesService } from "../_services/invoices.service";

@Component({
  selector: "app-invoice-create",
  templateUrl: "./invoice-create.component.html",
  styleUrls: ["./invoice-create.component.css"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class InvoiceCreateComponent implements OnInit {
  customer: Customer;

  constructor(
    private customerService: CustomersService,
    private invoiceService: InvoicesService,
    private vehiclesServices: VehiclesService,
    private toastService: GlobalToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    let id_customer = this.route.snapshot.paramMap.get("id");
    customerService.get({ id: id_customer }).subscribe(response => {
      this.customer = response[0];
    });
  }

  ngOnInit() { }

  submitForm(vehicle) {
    this.vehiclesServices.add(vehicle).subscribe(
      result => {
        let invoice = new Invoice();
        invoice.id_customer = this.customer.id;
        invoice.id_vehicle = vehicle.id ? vehicle.id : result.id;
        invoice.quote = true;
        this.invoiceService.add(invoice).subscribe(
          result => {
            this.router.navigate(["/invoice", result.id]);
          },
          () => this.toastService.show("Ajout devis", "Erreur ajout devis !")
        );
      },
      () => this.toastService.show("Ajout devis", "Erreur ajout véhicule !")
    );
  }
}
