import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Configuration } from "../_models";

@Injectable()
export class ConfigurationsService {
  constructor(private http: HttpClient) {}

  public get(params = {}, offset = 1, limit = 20): Observable<Configuration[]> {
    return this.http
      .post<Configuration[]>("api/configurations/get", {
        payload: { params, offset, limit }
      })
      .pipe(
        map((response: any) =>
          response.payload.map(configuration =>
            Configuration.fromObject(configuration)
          )
        )
      );
  }

  public logo(logo): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("logo", logo, logo.name);
    return this.http.post("api/configurations/logo", formData);
  }

  public add(configuration): Observable<any> {
    return this.http.put<any>("api/configurations/add", {
      payload: { configuration }
    });
  }

  public update(configuration): Observable<any> {
    return this.http.patch<any>("api/configurations/update", {
      payload: { configuration }
    });
  }

  public delete(name): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: { name } }
    };
    return this.http.delete<any>("api/configurations/delete", options);
  }
}
