import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class GlobalVariables {
  private searchText: string = "";
  searchTextSubject: BehaviorSubject<string>;
  constructor() {
    this.searchTextSubject = new BehaviorSubject<string>(this.searchText);
  }

  setSearchText(newValue: string, notify: boolean = true) {
    this.searchText = newValue;
    if (notify)
      this.searchTextSubject.next(newValue);
  }
}