import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Vehicle } from "../_models";

@Injectable()
export class VehiclesService {
  constructor(private http: HttpClient) {}

  public get(params = {}, offset = 1, limit = 20): Observable<Vehicle[]> {
    return this.http
      .post<Vehicle[]>("api/vehicles/get", {
        payload: { params, offset, limit }
      })
      .pipe(
        map((response: any) =>
          response.payload.map((vehicle: any) => Vehicle.fromObject(vehicle))
        )
      );
  }

  public add(vehicle): Observable<any> {
    return this.http.put<any>("api/vehicles/add", { payload: { vehicle } });
  }

  public update(vehicle): Observable<any> {
    return this.http.patch<any>("api/vehicles/update", {
      payload: { vehicle }
    });
  }

  public getDataFromRegistration(registration: string): Observable<Vehicle> {
    const option = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*"
      })
    };
    return this.http
      .get<any>(
        "https://cors-anywhere.herokuapp.com/https://www.partauto.fr/partauto/api/licence_plate?licence_plate=" +
          registration,
        option
      )
      .pipe(
        map(result => {
          let vehicle = new Vehicle();
          vehicle.registration = registration;
          vehicle.name = result.brand.name + " " + result.modelGroup.name;
          vehicle.motorisation = result.type.name;
          return vehicle;
        })
      );
  }

  public getDataFromRegistrationtest(registration: string): Observable<any> {
    return this.http.get<any>("api/vehicles/getDataFromRegistration");
  }
}
