export class Vehicle {
  constructor(
    public id: number = 0,
    public vin: string = "",
    public registration: string = "",
    public name: string = "",
    public registration_date: Date = new Date(),
    public motorisation: string = "",
    public kilometrage: number = 0
  ) {}

  static fromObject(vehicle) {
    return new Vehicle(
      vehicle.id,
      vehicle.vin,
      vehicle.registration,
      vehicle.name,
      new Date(vehicle.registration_date),
      vehicle.motorisation,
      vehicle.kilometrage
    );
  }
}
