import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RateMo } from '../_models';

@Injectable()
export class RatesMoService {
  constructor(private http: HttpClient) {}

  public get(params = {}, offset = 1, limit = 20): Observable<RateMo[]> {
    return this.http
      .post<RateMo[]>("api/rates_mo/get", { payload: {params, offset, limit} })
      .pipe(
        map((response: any) =>
          response.payload.map(rate_mo => RateMo.fromObject(rate_mo))
        )
      );
  }

  public add(rate_mo): Observable<any> {
    return this.http.put<any>("api/rates_mo/add", { payload: {rate_mo} });
  }

  public update(rate_mo): Observable<any> {
    return this.http.patch<any>("api/rates_mo/update", { payload: {rate_mo} });
  }

  public delete(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: {id} }
    };
    return this.http.delete<any>("api/rates_mo/delete", options);
  }
}
