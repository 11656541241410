import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Customer } from "../_models";
import { map } from "rxjs/operators";

@Injectable()
export class CustomersService {
  constructor(private http: HttpClient) { }

  public get(params = {}, offset = 1, limit = 20): Observable<Customer[]> {
    return this.http
      .post<Customer[]>("api/customers/get", { payload: { params, offset, limit } })
      .pipe(
        map((response: any) =>
          response.payload.map(customer => Customer.fromObject(customer))
        )
      );
  }

  public getCount(params = {}): Observable<number> {
    return this.http
      .post<any>("api/customers/getCount", {
        payload: { params }
      })
      .pipe(
        map((response: any) => {
          return response.payload[0].count
        })
      );
  }


  public add(customer): Observable<any> {
    return this.http.put<any>("api/customers/add", { payload: { customer } });
  }

  public update(customer): Observable<any> {
    return this.http.patch<any>("api/customers/update", { payload: { customer } });
  }

  public delete(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: { id } }
    };
    return this.http.delete<any>("api/customers/delete", options);
  }

}
