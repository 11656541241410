import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Worker } from "../_models/workers";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class WorkersService {
  constructor(private http: HttpClient) {}

  public get(params = {}, offset = 1, limit = 20): Observable<Worker[]> {
    return this.http
      .post<Worker[]>("api/workers/get", { payload: { params, offset, limit } })
      .pipe(
        map((response: any) =>
          response.payload.map(worker => Worker.fromObject(worker))
        )
      );
  }

  public add(worker): Observable<any> {
    return this.http.put<any>("api/workers/add", { payload: { worker } });
  }

  public update(worker): Observable<any> {
    return this.http.patch<any>("api/workers/update", { payload: { worker } });
  }

  public delete(id): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      body: { payload: { id } }
    };
    return this.http.delete<any>("api/workers/delete", options);
  }
}
