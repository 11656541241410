import { Component, OnInit } from "@angular/core";
import { InvoicesService } from "../_services/invoices.service";
import { Invoice, Customer } from "../_models";
import { ActivatedRoute } from "@angular/router";
import { CustomersService } from "../_services/customers.service";
import { fadeInAnimation } from "../_animations";
import { Utils } from "../_utils/utils";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.css"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" },
})
export class InvoiceComponent implements OnInit {
  invoice: Invoice = new Invoice();
  customer: Customer;
  paymentMethod: string = "";
  invoices: Invoice[];
  selectedInvoice: Invoice;
  selectCredit: boolean;
  constructor(
    private invoicesService: InvoicesService,
    private route: ActivatedRoute,
    private customersService: CustomersService
  ) {
    let id_invoice = this.route.snapshot.paramMap.get("id");
    invoicesService.get({ "invoices.id": id_invoice }).subscribe((invoices) => {
      if (invoices.length != 1) console.log("not found");
      else {
        this.invoice = invoices[0];
        console.log(this.invoice);
        this.paymentMethod = this.invoice.payment_method;
        customersService
          .get({ id: this.invoice.id_customer })
          .subscribe((customers) => {
            if (customers.length != 1) console.log("not found");
            else {
              this.customer = customers[0];
              invoicesService
                .get({ "invoices.id_customer": this.customer.id })
                .subscribe((invoices) => {
                  this.invoices = invoices.filter(
                    (invoice) => invoice.number_invoice != null
                  );
                  for (const assetInvoice of this.invoices) {
                    if (assetInvoice.id == this.invoice.id_asset_invoice) {
                      this.selectedInvoice = assetInvoice;
                      this.selectCredit = true;
                    }
                  }
                });
            }
          });
      }
    });
  }

  formatDate(date: Date) {
    return Utils.formatDate(date);
  }

  fieldsChange(values: any): void {
    if (!this.selectCredit) {
      const asset = 0;
      const id_asset_invoice = null;
      this.invoicesService
        .update({
          ...this.invoice,
          asset: asset,
          id_asset_invoice: id_asset_invoice,
        })
        .subscribe((result) => {
          this.invoice.asset = asset;
          this.invoice.id_asset_invoice = id_asset_invoice;
          this.selectedInvoice = null;
        });
    }
  }

  switchInvoiceType() {
    this.invoicesService
      .update({ ...this.invoice, quote: false })
      .subscribe((result) => {
        if (this.selectedInvoice) {
          this.invoicesService
            .update({
              ...this.selectedInvoice,
              used_asset: this.selectedInvoice.used_asset + this.invoice.asset,
            })
            .subscribe((result) => {
              location.reload();
            });
        } else {
          location.reload();
        }
      });
  }

  onCreditInvoiceChange(value) {
    const asset = Math.min(
      parseFloat(value.getTotalPrice()) - value.used_asset,
      parseFloat(this.invoice.getTotalPrice())
    );
    const id_asset_invoice = value.id;
    this.invoicesService
      .update({
        ...this.invoice,
        asset: asset,
        id_asset_invoice: id_asset_invoice,
      })
      .subscribe((result) => {
        this.invoice.asset = asset;
        this.invoice.id_asset_invoice = id_asset_invoice;
      });
  }

  onPaymentMethodChange(value) {
    this.invoicesService
      .update({ ...this.invoice, payment_method: value })
      .subscribe((result) => {
        this.invoice.payment_method = value;
      });
  }

  extract(invoice: Invoice) {
    let toSend = invoice.toSend(invoice);
    this.invoicesService.extract(toSend).subscribe((x) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([x], { type: "application/pdf" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      var link = document.createElement("a");
      link.href = data;
      link.download = invoice.getName() + ".pdf";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }

  ngOnInit() {}
}
