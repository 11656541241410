import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerInfosComponent } from './customer-infos/customer-infos.component';
import { CustomersComponent } from './customers/customers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { InvoiceCreateComponent } from './invoice-create/invoice-create.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { VehicleInfoComponent } from './vehicle-info/vehicle-info.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { SchedulingComponent } from './scheduling/scheduling.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { InvoicesComponent } from './invoices/invoices.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'customers', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', redirectTo: 'customers' },
  { path: 'invoices'                          , canActivate: [AuthGuard], component: InvoicesComponent },
  { path: 'customers'                          , canActivate: [AuthGuard], component: CustomersComponent },
  { path: 'vehicles'                           , canActivate: [AuthGuard], component: VehiclesComponent },
  { path: 'configuration'                      , canActivate: [AuthGuard], component: ConfigurationComponent },
  { path: 'scheduling'                         , canActivate: [AuthGuard], component: SchedulingComponent },
  { path: 'vehicles/infos/:id'                 , canActivate: [AuthGuard], component: VehicleInfoComponent },
  { path: 'customers/infos/:id/create-invoice' , canActivate: [AuthGuard], component: InvoiceCreateComponent },
  { path: 'customers/infos/:id'                , canActivate: [AuthGuard], component: CustomerInfosComponent },
  { path: 'invoice/:id'                        , canActivate: [AuthGuard], component: InvoiceComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: !environment.production,
        onSameUrlNavigation: 'reload'
      }

    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
